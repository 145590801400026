import { ActionType } from "../actionTypes";
import { PaginatedResponse } from "../types";

export enum AnalyticsEventStatus {
  SCHEDULED = "SCHEDULED",
  CANCELED = "CANCELED",
  IN_PROGRESS = "IN_PROGRESS"
}

export type AnalyticsEvent = {
  id: number,
  event_idr: string;
  recurring_root_event_idr: string | null;
  title: string;
  attendees: number[] | null;
  start_date: string;
  end_date: string;
  date_scheduled: string;
  cabinet_create_date: string;
  cabinet_update_date: string;
  status: AnalyticsEventStatus;
  is_busy: boolean;
  event_category: number | null;
  external_event_locations: number[];
  meeting: number | null;
  conference_provider: number;
  consolidated_location: number | null;
  recurrence_type: number;
  event_type: number | null;
  leader: number,
  inclusion: number
};

export type ExecutiveContact = {
  id: number;
  primary_email: string | null;
  primary_email_hashed: string;
  all_emails: string[] | null;
  all_emails_hashed: string[];
  name: string;
  title: string;
  contact_category: number | null;
  contact_type: number | null;
  is_resource: boolean
};

export type AbstractEventAnalyticsLabel = {
  id: number;
  name: string;
  color: string;
};

type AttendeeTableDatum = {
  id: number
  hours: number;
  attendee: number;
  contact_category: number
};

export type ExecutiveContactCategory =  AbstractEventAnalyticsLabel;
export type EventCategory =  AbstractEventAnalyticsLabel;
export type EventType =  AbstractEventAnalyticsLabel;
export type ConsolidatedLocation =  AbstractEventAnalyticsLabel;
export type RecurrenceType =  AbstractEventAnalyticsLabel;
export type ExecutiveContactType = AbstractEventAnalyticsLabel;
export type AnalysisInclusion = AbstractEventAnalyticsLabel;

export type EventAnalysisDatum = {
  data: {
    period: string,
    hours: number,
    id: number
  }[]
  meta: {
    total : number
  }
};

export type AnalyticsEventsMeta = {
  min_start_date: string | null;
  max_end_date: string | null;
  most_recent_log:  AnalyticsETLLogAction | null;
  most_recent_started_log_date: string | null;
  most_recent_completed_log_date: string | null;
  most_recent_queued_log_date: string | null;
};

export type EventAnalysisResponse = {
  keys: {[key: string]: AbstractEventAnalyticsLabel},
  indices: string[],
  meta: {[key: string]: string | number, total: number},
  data: {[key: string]: EventAnalysisDatum},
  aggregates: AnalyticsEventsMeta
};

export type FetchAnalyticsEventResponse = PaginatedResponse<
  {events: {[key: string]: AnalyticsEvent}, contacts: {[key: string]: ExecutiveContact}}
> & {
  meta: {
    count: number;
    next: string | null;
    previous: string | null, id_order: number[];
  } & AnalyticsEventsMeta;
};


export type ContactAnalysisResponse = {
  meta: {
    count: number,
    next: string | null;
    previous: string | null;
  }
  data: {attendee: number, hours: number}[]
  attendees: {
    [key: string]: ExecutiveContact
  }
};

export enum EventAnalysisInterval {
  DAY_INTERVAL = "daily",
  WEEK_INTERVAL = "weekly",
  MONTH_INTERVAL = "monthly",
  QUARTER_INTERVAL = "quarterly",
  ANNUAL_INTERVAL = "annually",
}

export enum EventAnalysisIndexBy {
  PERIOD = "period"
}

export enum EventAnalysisKeyBy {
  LOCATION_KEY = "consolidated_location",
  CATEGORY_KEY = "event_category",
  RECURRENCE_TYPE_KEY = "recurrence_type",
  EVENT_TYPE_KEY = "event_type",
}


export type EventAnalyticsState = {
  events_order: number[];
  events: {[key: string]: AnalyticsEvent};
  executive_contacts_reconciliation: {[key: string]: ExecutiveContact};
  executive_contacts_analysis: {[key: string]: ExecutiveContact};
  executive_contact_categories: {[key: string]: ExecutiveContactCategory};
  event_categories: {[key: string]: EventCategory};
  event_types: {[key: string]: EventType };
  attendeeTableData: AttendeeTableDatum[];
  consolidated_locations: {[key: string]: ConsolidatedLocation};
  eventAnalysisResponse?: EventAnalysisResponse
  recurrence_types: {[key: string]: RecurrenceType};
  contact_types: {[key: string]: ExecutiveContactType}
  meta: AnalyticsEventsMeta
  analysis_inclusions: {[key: string]: AnalysisInclusion}
  analytics_colors: AnalyticsEventColor[]
};

export type FetchLeaderAnalysisQuery = {
  start_date: string,
  end_date: string,
  leaders: string[]
};

export type FetchEventAnalysisPayload = {
  index_by: string,
  key_by: string,
  interval: string,
  query: FetchLeaderAnalysisQuery
};

export enum AnalyticsETLLogAction {
  STARTED = "started",
  ERROR = "error",
  COMPLETED = "completed",
  QUEUED = "queued",
  NOT_FETCHED = "not_fetched",
}

export type AnalyticsEventColor = {
  id: number;
  color: string | null; 
  organization: number;
  category: string | null;
  calendar: number;
  event_category: number | null;
  event_type: number | null;
};

export type EventAnalyticsAction = {
  type: ActionType.UPDATE_ANALYTICS_EVENTS,
  payload: {events: {[key: number]: AnalyticsEvent}, meta?: AnalyticsEventsMeta, order: number[]}
} |
{
  type: ActionType.SET_ANALYTICS_EVENTS,
  payload: {events: {[key: number]: AnalyticsEvent}, meta: AnalyticsEventsMeta, order: number[]}
} |
{type: ActionType.UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION, contacts: {[key: string]: ExecutiveContact}} |
{type: ActionType.SET_EXECUTIVE_CONTACTS_RECONCILIATION, contacts: {[key: string]: ExecutiveContact}} |
{type: ActionType.UPDATE_CONSOLIDATED_LOCATION, locations: {[key: string]: ConsolidatedLocation}} |
{type: ActionType.UPDATE_EVENT_CATEGORIES, categories: {[key: string]: EventCategory}} |
{type: ActionType.UPDATE_EVENT_TYPES, types: {[key: string]: EventType}} |
{type: ActionType.UPDATE_EXECUTIVE_CONTACT_CATEGORIES, categories: {[key: string]: ExecutiveContactCategory}} |
{type: ActionType.SET_EVENT_ANALYSIS_RESPONSE, analysis: EventAnalysisResponse} |
{type: ActionType.UPDATE_EXECUTIVE_CONTACTS_ANALYSIS, contacts: {[key: string]: ExecutiveContact}} |
{type: ActionType.DELETED_EVENT_CATEGORY, categoryId: EventCategory['id']} |
{type: ActionType.DELETED_EVENT_TYPE, typeId: EventType['id']} |
{type: ActionType.DELETED_EXECUTIVE_CONTACT_CATEGORIES, categoryId: ExecutiveContactCategory['id']} |
{type: ActionType.UPDATE_RECURRENCE_TYPE, types: {[key: string]: RecurrenceType}} |
{type: ActionType.SET_EXECUTIVE_CONTACT_TYPES, types: {[key: string]: ExecutiveContactType}} |
{type: ActionType.UPDATE_ANALYTICS_META, meta: Partial<AnalyticsEventsMeta>} |
{type: ActionType.SET_ANALYSIS_INCLUSIONS, inclusions: {[key: string]: AnalysisInclusion}} |
{type: ActionType.SET_ANALYTICS_COLORS, colors: AnalyticsEventColor[]};

