import { Box, Typography } from "@mui/material";
import { CAL_ANALYTICS_LOADING } from "../../../resourceUrls";
import { AnalyticsETLLogAction } from "../../../store";

const FetchInProgress = ({etlStatus}: {etlStatus?: AnalyticsETLLogAction}) => {
  return <Box sx={{width: "100%", display: "block"}}>
    <Box sx={{margin: "auto", padding: 12, textAlign: "center"}}>
      <img 
        src={CAL_ANALYTICS_LOADING}
        alt="Loading..."
      />
      <Typography variant="h6">Hang tight - we're still crunching the numbers for you.</Typography>
      <Typography variant="h6" sx={{marginTop: 2}}>
        You can refresh this page in a few minutes to see your insights.
      </Typography>
    </Box>
  </Box>;
};

export default FetchInProgress;