import { Box, Grid, List, ListItem, ListItemProps, Popover, styled, Typography } from "@mui/material";

import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import colors from "../../../colors";
import { DateTime } from "luxon";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoChevronDownOutline } from 'react-icons/io5';
import { EventAnalysisInterval } from "../../../store";
import { FieldRef, RangeFieldSection, SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro";

export type ReconciliationDatePickerProps = {
  start: DateTime | undefined
  end: DateTime | undefined
  handleChangeDates: (startDate: DateTime | undefined, endDate: DateTime | undefined) => void
  tz?: string
  interval?: EventAnalysisInterval
  setInterval?: (interval: EventAnalysisInterval) => void
  maxDate?: DateTime
  minDate?: DateTime
};

export enum PeriodOption {
  previousWeek = "Last 7 Days",
  previousMonth = "Last 30 Days",
  previousQuarter = "Last 3 Months",
  previousHalf = "Last 6 Months",
  previousYear = "Last 1 Year",
}


const StyledListItem = styled(
  ListItem, { label: "StyledListItem", shouldForwardProp: (prop) => prop !== "activePeriod" }
)<ListItemProps & { activePeriod?: string, period: string }>(({ activePeriod, period }) => ({
  backgroundColor: activePeriod === period ? colors.navyPrimary : colors.black100,
  color: activePeriod === period ? colors.white900 : colors.black800,
  borderRadius: "6px",
  paddingTop: 12,
  paddingBottom: 12,
  marginBottom: 8,
  "&:hover": {
    cursor: "pointer",
    backgroundColor: activePeriod === period ? colors.navyPrimary : colors.black200,
    color: activePeriod === period ? colors.white900 : colors.black900,
  },
}));

const StyledButton = styled( CabButton, {label: "StyledCabButton"})(() => ({
  border: '1px solid',
  borderColor: colors.black200,
  padding: 8, 
  minWidth: 150,
  '&:hover': {
    backgroundColor: colors.black50,
    borderColor: colors.black900,
  },
}));

const ReconciliationDatePicker = ({
  tz, handleChangeDates, start, end, interval, setInterval, maxDate, minDate
}: ReconciliationDatePickerProps): ReactElement => {

  const fieldRef = useRef<FieldRef<RangeFieldSection>>(null);
  
  const [periodOption, setPeriodOption] = useState<PeriodOption | undefined>(PeriodOption.previousWeek);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const today = DateTime.now().setZone(tz).set(
    {hour: 0, minute: 0, second: 0, millisecond: 0}
  );

  const [pickerPopover, setPickerPopover] = 
    useState<HTMLElement | null>(null);

  const openCabGenericDatePicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.persist();
    setPickerPopover(e.currentTarget);
  };


  const periodDates = useMemo(() => {
    const coerceTodayToEnd = today.set({hour: 23, minute: 59, second: 59, millisecond: 999});
    return {
      [PeriodOption.previousWeek]: { start: today.minus({ days: 6 }), end: coerceTodayToEnd },
      [PeriodOption.previousMonth]: { start: today.minus({ months: 1 }), end: coerceTodayToEnd },
      [PeriodOption.previousQuarter]: { start: today.minus({ months: 3 }), end: coerceTodayToEnd },
      [PeriodOption.previousHalf]: { start: today.minus({ months: 6 }), end: coerceTodayToEnd },
      [PeriodOption.previousYear]: { start: today.minus({ years: 1 }), end: coerceTodayToEnd },
    };
    // Note: Need a consistent hashable value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today.toISO()]);

  const handleIntervalGating = useCallback((newStart: DateTime | undefined, newEnd: DateTime | undefined) => {
    const diffGreaterThanOneYear = newStart ? (newEnd?.diff(newStart, 'years').years || 0) > 1 : false;
    const diffGreaterThanTwoMonths = newStart ? (newEnd?.diff(newStart, 'months').months || 0) > 2 : false;
    if (diffGreaterThanTwoMonths && !diffGreaterThanOneYear) {
      if (interval === EventAnalysisInterval.DAY_INTERVAL && setInterval) {
        setInterval(EventAnalysisInterval.WEEK_INTERVAL);
      }
    } else if (diffGreaterThanOneYear) {
      const intervalNotPermitted = interval === EventAnalysisInterval.DAY_INTERVAL || interval ===
        EventAnalysisInterval.WEEK_INTERVAL;
      if (intervalNotPermitted && setInterval) {
        setInterval(EventAnalysisInterval.MONTH_INTERVAL);
      }
    }
  }, [interval, setInterval]);


  // Note: This handles the selection of predefined period options
  useEffect(() => {
    if (periodOption) {
      handleChangeDates(periodDates[periodOption]["start"], periodDates[periodOption]["end"]);
      handleIntervalGating(periodDates[periodOption]["start"], periodDates[periodOption]["end"]);
    }
    // Note: Need a consistent hashable value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOption, periodDates, today.toISO()]);

  useEffect(() => {
    if (start) {
      setStartDate(start);
    }
    if (end) {
      setEndDate(end);
    }
  }, [end, start]);
  


  const handleRangeChange = (startValue: DateTime | undefined, endValue: DateTime | undefined) => {
    const presetPeriod = Object.entries(periodDates).find(
      ([key, value]) => startValue === value["start"] && endValue === value["end"]
    );

    if (startValue && endValue) {
      handleIntervalGating(startValue, endValue);
    }    
    if (presetPeriod) {
      setPeriodOption(presetPeriod[0] as PeriodOption);
    } else {
      setPeriodOption(undefined);
    }
    if (start !== startValue ) {
      handleChangeDates(startValue, endValue);
    }
    if (end !== endValue) {
      const endValueCoercedToDayEnd = endValue?.set({hour: 23, minute: 59, second: 59, millisecond: 999});
      handleChangeDates(startValue, endValueCoercedToDayEnd);
    }
  };

  const setDateRange = (startValue: DateTime | undefined, endValue: DateTime | undefined) => {
    if (startValue && endValue) {
      if (startValue > endValue) {
        setStartDate(startValue);
        setEndDate(startValue.plus({day: 1}));
      } else {
        setStartDate(startValue);
        setEndDate(endValue);
      }
    }
  };

  const onClosePopover = () => {
    handleRangeChange(startDate, endDate);
    setPickerPopover(null);
  };

  const handleFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab") {
        const activeSectionIndex =
          fieldRef.current?.getActiveSectionIndex() ?? 0;

        if (event.shiftKey && activeSectionIndex > 0) {
          event.preventDefault();
          fieldRef.current?.setSelectedSections(activeSectionIndex - 1);
        }

        if (
          !event.shiftKey &&
          activeSectionIndex < (fieldRef.current?.getSections()?.length || 0) - 1
        ) {
          event.preventDefault();
          fieldRef.current?.setSelectedSections(activeSectionIndex + 1);
        }
      }
    },
    []
  );

  const value = periodOption ? periodOption : (start && end) ? 
    `${start.toFormat('MM/dd/yy')} to ${end.toFormat('MM/dd/yy')}` : "Select Date Range";

  return <>
    <Box onClick={openCabGenericDatePicker}>
      <StyledButton buttonType='text'>
        <Box display="flex" flexDirection="row" alignItems="center" width="max-content">
          <Typography sx={{display: "flex", flex: "none"}}>{value}</Typography> 
          <CabIcon sx={{ marginLeft: 2 }} Icon={IoChevronDownOutline} />
        </Box>
      </StyledButton>
    </Box>
    <Popover
      open={!!pickerPopover}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={pickerPopover}
    >
      <Grid 
        container
        padding={2}
        display="flex"
        flexDirection="row"
        flexWrap={"wrap"}
        width={550}
      >
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14, color: colors.black700 }}>Select date range</Typography>
          <List>
            {Object.entries(PeriodOption).filter(([k, v]) => value !== "").map(
              ([k, v]) => <StyledListItem
                key={k}
                activePeriod={periodOption}
                period={v}
                onClick={() => setPeriodOption(v)}
              >
                {v}
              </StyledListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={8} display="flex" sx={{
          "& .MuiDayPicker-slideTransition": { minHeight: 210 },
          "& .MuiCalendarPicker-root": { margin: 0 },
          alignItems: "center",
          justifyContent: "end"
        }}>
          <Box sx={{ 
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            '& .MuiDayPicker-header .MuiDayPicker-weekDayLabel': {
              margin: 0,
            }
          }}>
            <StaticDateRangePicker
              value={[startDate || null, endDate || null]}
              timezone={tz}
              onChange={(startEndArr) => startEndArr[0]?.isValid && startEndArr[1]?.isValid ? 
                setDateRange(startEndArr[0], startEndArr[1]) : undefined}
              slotProps={{
                actionBar: {
                  hidden: true
                },
                toolbar: {
                  hidden: true,
                },
              }}
              sx={{
                '& .MuiPickersDay-root': {
                  color: 'inherit',
                  '&.MuiDateRangePickerDay-day.Mui-selected' : {
                    backgroundColor: colors.navyPrimary,
                    '&:hover': {
                      backgroundColor: colors.navyLight,
                    },
                  },
                },
                '& .MuiDateRangePickerDay-root': {
                  '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
                    backgroundColor: colors.navyPrimary,
                    '&:hover': {
                      backgroundColor: colors.navyLight,
                    },
                    '&.MuiDateRangePickerDay-hiddenDayFiller': {
                      backgroundColor: 'inherit'
                    }
                  }
                }
              }}
            />
          </Box>
        </Grid>
        <Box display="flex" width={'100%'} sx={{
          alignItems: "center",
          borderTop: `1px solid ${colors.black100}`,
          justifyContent: 'space-between',
          marginTop: 2,
          paddingTop: 2
        }}>
          <Box width={'50%'}>
            <SingleInputDateRangeField
              size="small"
              value={[startDate || null, endDate || null]}
              onChange={(startEndArr) => startEndArr[0]?.isValid && startEndArr[1]?.isValid ? 
                setDateRange(startEndArr[0], startEndArr[1]) : undefined}
              fullWidth
              unstableFieldRef={fieldRef}
              slotProps={{
                textField: {
                  onKeyDown: handleFieldKeyDown
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover': {
                    backgroundColor: colors.black50
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.black800,
                    borderWidth: 1
                  },
                  '& fieldset': {
                    borderColor: colors.black200,
                  },
                }
              }}
            />
          </Box>
          <Box>
            <CabButton
              color={'primary'} 
              onClick={onClosePopover} 
              sx={{height: 40}}
            >
              Done
            </CabButton>
          </Box>
        </Box>
      </Grid>
    </Popover>
  </>;
};

export default ReconciliationDatePicker;